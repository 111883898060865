.file-attestation-confirmation {
    margin: 1em auto 0;
    width: 80%;
}

.file-attestation-confirmation > span {
    display: grid;
    grid-template-columns: 8em 1fr;
}

.file-attestation-confirmation > .attestation-confirmation-checkbox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5em;
}

.file-attestation-confirmation > .attestation-confirmation-checkbox .attestation-confirmation-checkbox-box {
    padding-top: 0;
}

.file-attestation-confirmation > .attestation-confirmation-checkbox .MuiFormControlLabel-label {
    max-height: 30vh;
    overflow-y: auto;
}

.file-attestation-confirmation > span > .attestation-confirmation-label {
    display: flex;
    align-items: center;
    font-family: var(--font-lato-bold);
    font-size: var(--font-size-small);
    margin-bottom: 1.5em;
}

.file-attestation-confirmation > .attestation-confirmation-button,
.file-attestation-confirmation > .attestation-rejection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.file-attestation-confirmation > .attestation-confirmation-button > button,
.file-attestation-confirmation > .attestation-rejection > button {
    height: var(--button-height);
    margin: 1em;
}

.file-attestation-confirmation > .attestation-rejection > .reject-comment {
    margin: 1em;
}