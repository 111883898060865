.acting-for {
  display: flex;
  align-items: center;
}

.acting-for .acting-for-label,
.acting-for .acting-for-because-label {
  font-size: var(--font-size-small);
  font-weight: bold;
  margin-right: 1em;
}

.acting-for {
  margin-left: auto;
}

.acting-for .acting-for-select,
.acting-for .acting-for-reason {
  margin-right: 1.5em;
}

.acting-for .acting-for-select {
  width: 13.5em;
}

.acting-for .acting-for-reason {
  width: 20em;
}

.acting-for .viewing-as-reason {
  width: 19.5em;
}
