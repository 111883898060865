.group-view-attributes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.group-view-attributes > div {
  display: flex;
  flex-direction: column;
  margin: 1em 2em;
}

.group-view-attributes > div > .view-half-width {
  display: grid;
  grid-template-columns: .4fr .4fr;
}

.group-view-attributes > div > span:not(:last-child) {
  margin-bottom: 1em;
}