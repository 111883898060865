.select-input > .select-input-label,
.select-input > .select-input-select {
  min-width: 14em;
}

.select-input > .select-input-label-padded,
.select-input > .select-input-select-padded {
  margin: 1em 2em;
  min-width: 14em !important;
}

.select-input .select-input-select-padded.Mui-error,
.select-input .select-input-select.Mui-error {
  margin-bottom: 0;
}

.select-input-halfwidth > .select-input-label,
.select-input-halfwidth > .select-input-select {
  margin: 1em 0;
}

.select-input-halfwidth > .select-input-label-padded,
.select-input-halfwidth > .select-input-select-padded {
  margin: 1em 2em;
}

.select-input-halfwidth .select-input-select-padded.Mui-error,
.select-input-halfwidth .select-input-select.Mui-error {
  margin-bottom: 0;
}

.select-input .validateText,
.select-input-halfwidth .validateText {
  margin-left: 4em;
}
