.subgroupProviders .provider-list-row {
    display: grid;
    grid-template-columns: 6fr 1fr;
}

.subgroupProviders .provider-list-row .provider-row-data {
    display: grid;
    grid-template-columns: 0.75fr 2fr 1fr 1fr;
}

.providers .providers-list .provider-list-row .colHeader {
    font-family: var(--font-lato-bold);
}

.subgroupProviders .provider-list-row > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}