.affiliation-button-container {
    max-width: 18.5em;
    margin: 0 auto;
}

.affiliation-button-container > .affiliation-button-info {
    min-height: 3em;
}

.affiliation-button-container > .affiliation-button-info > .affiliation-button-info-value {
    display: flex;
    flex-direction: column;
}


.affiliation-button-container > .affiliation-button-info > .affiliation-button-info-value > div > a {
    cursor: pointer;
    font-size: 0.875rem;
    text-decoration: underline;
    color: var(--darker-default-color);
}

.affiliation-button-container > .affiliation-button > button {
    margin: 0 1em;
    white-space: nowrap;
    min-width: initial;
}