.program-grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.program-cycle-row > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.programCycles .program-cycle-row {
    display: grid;
    grid-template-columns: 6.5fr 1.5fr;
}