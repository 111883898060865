.next-cycle {
  display: flex;
  align-items: center;
  margin-left:  1.5em;
}

.next-cycle .next-cycle-label {
  font-size: var(--font-size-small);
  font-weight: bold;
  margin-right: 1em;
}
  
.next-cycle .next-cycle-name {
  color: var(--darker-default-color);
}