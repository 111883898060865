.login-container > .login-panel {
  width: 30vw;
}

.login-container > .login-panel > .login-card-content {
  display: flex;
  flex-direction: column;
}

.login-container > .login-panel > .login-card-content > .submit-button {
  margin-top: 1em;
  display: flex;
  gap: 1em;
  text-wrap: nowrap;
}

.login-container > .login-panel > .login-card-content .text-input .validateText {
  margin-left: 2em;
}

.login-container > .login-links {
  display: flex;
  justify-content: space-between;
}

.login-container > .login-links > a {
  padding: 1em;
  text-decoration: none;
}