.edit-user-attributes .roles {
  margin: 0 2em;
  min-width: 15em;
}

.select-input {
  margin: 1em 2em;
  min-width: 14em;
}

.edit-user-attributes .last-login-status {
    margin-left: 0.3em;
    font-size: smaller;
    color: var(--color-medium-grey);
}