.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.page-not-found label {
  font-size: 2em;
  font-weight: bold;
  line-height: 2em;
}

.page-not-found a {
  text-decoration: underline;
}
