.edit-subgroup-attributes > .edit-subgroup-attributes-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.edit-subgroup-attributes > .edit-subgroup-attributes-fields > div {
    display: flex;
    flex-direction: column;
}

.edit-subgroup-attributes > .edit-subgroup-attributes-fields > div > .half-width .text-input-field-padded > label,
.edit-subgroup-attributes > .edit-subgroup-attributes-fields > div > .half-width .text-input-field-padded > div,
.edit-subgroup-attributes > .edit-subgroup-attributes-fields > div > .half-width .select-input > .select-input-label,
.edit-subgroup-attributes > .edit-subgroup-attributes-fields > div > .half-width .select-input > .select-input-select {
    min-width: unset;
}

.edit-subgroup-attributes > .edit-subgroup-attributes-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 0 1.25em;
}