.program-product .button-remove {
    margin: 1em;
}

.program-product .button-add {
    margin: 1em;
}


.program-product .product-list-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.program-product .product-name {
    font-weight: bold;
}

.program-product .product-button {
    min-width: 6.5em !important;
    height: var(--button-height);
}
