.list-files .upload-and-download-all-buttons-row {
    display: flex;
    justify-content: space-between;
}

.list-files .upload-button {
    margin: 2.5em 0;
}

.list-files .search-filter-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.list-files .row.no-hand {
    cursor: default;
}