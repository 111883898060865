.affiliations-table th, .affiliations-table td {
    border-bottom: none;
}

.affiliations-table tr.aff-col-header1 th {
    padding-bottom: 0;
    font-family: var(--font-lato-bold);
    font-size: var(--font-size-small);
}

.affiliations-table tr.aff-col-header1 th.aff-col-row-header {
    border-right: 1px solid var(--color-medium-grey);
    border-bottom: 1px solid var(--color-medium-grey);
}

.affiliations-table tr.aff-col-header2 th {
    padding-top: 0;
    border-bottom: 1px solid var(--color-medium-grey);
}

.affiliations-table th.aff-row-header1 {
    padding-right: 0.5em;
    max-width: 10em;
}

.affiliations-table th.aff-row-header2 {
    padding-left: 0;
    border-right: 1px solid var(--color-medium-grey);
}

.affiliations-table th.aff-row-header1 > *,
.affiliations-table th.aff-row-header2 > * {
    padding-top: 3em;

}

.affiliations-table td {
    white-space: nowrap;
    min-width: 8.5em;
}

.provider-affiliations {
    overflow-x: hidden;
}

.provider-affiliations > .flexRow .affiliation-button button {
    margin: 1em;
    height: var(--button-height);
    min-width: 6.5em;
}