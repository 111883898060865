header .notification-badge {
    margin-right: 1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
  }
  
  header .notification-badge.empty > span,
  header .notification-badge.empty > .notification-badge-number {
    color: transparent;
    background-color: var(--badge-color-empty);
  }
  
  header .notification-badge > span {
    height: 1.8rem;
    border-radius: 50%;
    background-color: var(--badge-color);
  }
  
  header .notification-badge-number {
    font-size: 1rem;
    font-weight: 600;
    padding: 4px;
  }
  
  .notifications-popper {
    z-index: 1000;
  }
  
  .notifications-popper * {
    color: var(--link-color-dark);
    transition: none !important;
  }
