.edit-user-view-attributes > div {
  display: flex;
  flex-direction: column;
  margin: 1em;
}

.edit-user-view-attributes > div > span .user-data {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.edit-user-view-attributes > div > span:not(:last-child) {
  margin-bottom: 1em;
}

.edit-user-view-attributes > div > .user-roles {
  margin: 0 2em;
  min-width: 15em;
}

.edit-user-view-attributes > div > .user-checkboxes .user-attributes-checkbox {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-left: -11px;
  margin-top: -11px;
  margin-right: 16px;
}

.edit-user-view-attributes > div > .user-checkboxes .flexFill {
  height: 3em; /* TODO: temp workaround, the "fill" doesn't work here  */
  flex: none;
}

.edit-user-view-attributes .last-login-status {
  margin-left: 0.3em;
  font-size: smaller;
}
