.import-file-input {
    display: grid;
    grid-template-columns: 20% 60% 10% 10%;
    margin: 0 1.25em;
 }

    .import-file-input .MuiButtonBase-root {
        align-self: center;
    }

    .import-file-input .file-input {
        align-self: center;
    }