@font-face {
  font-family: 'Weather';
  src: url('./fonts/Weather.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('./fonts/Lato-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('./fonts/Lato-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-weather: 'Weather';
  --font-lato-bold: 'Lato-Bold';
  --font-lato-regular: 'Lato-Regular';

  --font-size-base: 1em;
  --font-size-very-small: 0.7em;
  --font-size-small: 0.8em;
  --font-size-large: 1.4em;
}

header {
  --font-size-nav: 0.9em;
}