.cyclePrograms .program-list-row {
    display: grid;
    grid-template-columns: 1fr 15em;
}

.cyclePrograms .program-list-row > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cyclePrograms .program-list-row .program-row-data {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-gap: 1em;
}

.cyclePrograms #deactivate {
    margin: 0 1em;
}