
.edit-provider-attributes > .edit-provider-attributes-fields > div {
    display: flex;
    flex-direction: column;
}

.edit-provider-attributes > .edit-provider-attributes-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 0 1.25em;
}

.provider-attributes-label {
    font-size: var(--font-size-large);
    text-align: center;
}

.provider-imported-label {
    padding-top: .3em;
}

.provider-edited-label {
    padding-top: .8em;
}

.edit-provider-attributes > .edit-provider-attributes-fields > div > div.text-field {
    margin: 1em 2em;
    min-height: 3.5em;
}
