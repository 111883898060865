.reset-password-container > .reset-password-panel {
    width: 30vw;
}

    .reset-password-container > .reset-password-panel > .reset-password-card-content {
        display: flex;
        flex-direction: column;
    }

        .reset-password-container > .reset-password-panel > .reset-password-card-content > .submit-button {
            margin-top: 1em;
        }

        .reset-password-container > .reset-password-panel > .reset-password-card-content .text-input .validateText {
            margin-left: 2em;
        }
