.file-upload {
    display: grid;
    grid-template-columns: 25% 25% 50%;
    margin: 0 1.25em 1.25em 1.25em;
}

    .file-upload .MuiButtonBase-root {
        align-self: center;
    }

    .file-upload .file-input {
        align-self: center;
    }

    .file-upload .MuiFormControl-root {
        align-self: center;
    }

    .file-upload .text-input {
        align-self: center;
    }

.file-upload-buttons {
    margin-right: 0.5em;
    margin-bottom: 1.25em;
    float: right;
}