.user-message-panel > .snackbar {
    top: calc(var(--header-height) + 4px);
    right: 40px;
}

    .user-message-panel > .snackbar .MuiAlert-message {
        max-width: 30vw;
    }

.user-message-panel > .snackbar > .MuiAlert-filledSuccess {
  background-color: var(--background-color-success);
}

.user-message-panel > .snackbar > .MuiAlert-filledError {
  background-color: var(--background-color-error);
}

.user-message-panel > .snackbar > .MuiAlert-filledInfo {
  background-color: var(--background-color-waiting);
}