.set-password-panel {
  min-width: 30vw;
}

.set-password-card-content {
  display: flex;
  flex-direction: column;
}

.set-password-card-content .submit-button {
  margin-top: 1em;
}

.set-password-card-content .text-input .validateText {
  margin-left: 2em;
}

.set-password-card-content .set-password-rules {
  font-size: var(--font-size-small);
}

.set-password-card-content .set-password-rules p {
  font-weight: bolder;
}

.set-password-card-content .set-password-rules ul {
  padding-left: 20px;
}

.set-password-card-content .set-password-rules li {
  margin-top: 0.3em;
  margin-bottom: 0.5em;
}

.set-password-card-content .set-password-rules code {
  padding-left: 0.5em;
  white-space: pre;
}