.authenticated-layout {
    display: grid;
    grid-template-rows: var(--header-height) calc(100vh - var(--header-height));
}

.authenticated-layout > .page-content {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.authenticated-layout > .page-content > .authenticated-content {
  flex: 1;
}
