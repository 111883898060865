.providers .import-buttons {
    margin: 2.5em 0;
}
    
    .providers .import-buttons > button {
        height: var(--button-height);
    }

    .providers .import-buttons > button:not(:last-child) {
        margin-right: 1.5em;
    }

    .providers .import-buttons .followup-message:not(:first-child) {
        /* when there is at least one button to the left of message */
        width: 56em;
        float: right;
        padding-top: 0.5em;
    }

    .providers .import-buttons .followup-message:first-child{  
        /* when there are no buttons to the left of message */
        margin-left: 38em;
    }

.providers .providers-list .provider-row {
    display: grid;
    grid-template-columns: 1fr 15em;
}

.providers .providers-list .provider-row .provider-row-data {
    display: grid;
    grid-template-columns: 0.55fr 1.75fr 0.65fr 0.65fr 1.5fr 1.5fr;
    grid-gap: 0.5em;
}

.providers .providers-list .provider-row .provider-row-data > span > div > .provider-row-data-link {
    font-size: 0.875rem;
}

.providers .providers-list .provider-row > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-self: end;
}

