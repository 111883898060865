.acnProviders .provider-list-row {
    display: grid;
    grid-template-columns: 4.75fr 2fr;
}

.acnProviders .provider-list-row .provider-row-data {
    display: grid;
    grid-template-columns: 0.75fr 1.75fr 0.75fr 0.75fr 2fr;
}

.acnProviders .provider-list-row .provider-row-data > span > div > .provider-row-data-link {
    font-size: 0.875rem;
    text-decoration: underline;
    color: var(--darker-default-color);
}

.acnProviders .provider-list-row > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-self: flex-end;
}

.acnProviders .provider-list-row .result-action-button-remove {
    margin: 1em;
}