.viewReport {
    --width-to-height-view-ratio: 1.778;
    --report-margin-top: 1em;
    margin-top: var(--report-margin-top);
}

.viewReport > .report-style-class {
    display: flex;
    justify-content: center;
}

.viewReport > .report-height-limiting > iframe {
    --iframe-height: calc(100vh - var(--header-height) - var(--report-margin-top));
    height: var(--iframe-height) !important;
    width: calc(var(--iframe-height) * var(--width-to-height-view-ratio)) !important; /* Maintaining 16:9 aspect ratio */
}

.viewReport > .report-width-limiting > iframe {
    --iframe-width: 100vw;
    width: var(--iframe-width) !important;
    height: calc(var(--iframe-width) / var(--width-to-height-view-ratio)) !important; /* Maintaining 16:9 aspect ratio */
}