.acn-programs .acn-program-product-association-table .acn-program-product-association-col1 {
    border-right: 1px solid var(--color-medium-grey);
    width: 10em;
    max-width: 10em;
}

.acn-programs .acn-program-product-association-table .association-header .acn-program-product-association-header-product {
    font-family: var(--font-lato-bold);
    font-size: var(--font-size-small);
}

.acn-programs .acn-program-product-association-table .association-header .acn-program-product-association-col1,
.acn-programs .acn-program-product-association-table .association-header .acn-program-product-association-header-product {
    border-bottom: 1px solid var(--color-medium-grey);
}

.acn-programs .acn-program-product-association-table .association-header .acn-program-product-association-header-product,
.acn-programs .acn-program-product-association-table .acn-program-product-association-product-action {
    width: 6em;
}

.acn-programs .acn-program-product-association-table tbody td {
    border-bottom: none;
}