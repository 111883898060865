.import-roster .import-result-list .import-result-row .import-result-row-data.roster-import-result-row-data {
    display: grid;
    grid-template-columns: 1.5fr 2fr 1.25fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1em;
}

.import-roster .download-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
}

.import-roster .roster-search-filter-fields {
    display: grid;
    grid-template-columns: 50% 50%;
}
