.edit-acn-attributes > .edit-acn-attributes-fields > div {
    display: flex;
    flex-direction: column;
}

.edit-acn-attributes > .edit-acn-attributes-fields > div > .half-width .text-input-field-padded > label,
.edit-acn-attributes > .edit-acn-attributes-fields > div > .half-width .text-input-field-padded > div,
.edit-acn-attributes > .edit-acn-attributes-fields > div > .half-width .select-input > .select-input-label,
.edit-acn-attributes > .edit-acn-attributes-fields > div > .half-width .select-input > .select-input-select {
    min-width: unset;
}

.edit-acn-attributes > .edit-acn-attributes-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 0 1.25em;
}

.viewACN > div {
    display: flex;
    flex-direction: column;
    margin: 1em 2em;
}

.viewACN > div > span .acn-data {
    display: flex;
    flex-direction: column;
}

.viewACN > div > span:not(:last-child) {
    margin-bottom: 1em;
}