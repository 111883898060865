footer {
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}

footer > .footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

footer > .footer-links > * {
  font-size: var(--font-size-small);
  margin-left: 2em;
}

footer > .footer-links > div > a {
  margin-left: 0.3em;
}

footer > .footer-links a label > span {
  cursor: pointer;
}

footer > .footer-right {
  margin: 0 2em 0 auto;
  display: flex;
  align-items: center;
}

footer > .footer-right > .current-version {
  margin-right: 0.5em;
  font-size: var(--font-size-very-small);
}

footer > .footer-right > img {
  display: block;
  height: 35px;
  width: auto;
}
