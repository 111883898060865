.text-input {
  display: flex;
  flex-direction: column;
}

.text-input .text-input-field > label,
.text-input .text-input-field > div {
  margin: 1em 0;
  min-width: 14em;
}

.text-input .text-input-field > div.Mui-error {
  margin-bottom: 0;
}

.text-input .text-input-field-padded > label,
.text-input .text-input-field-padded > div {
  margin: 1em 2em;
  min-width: 14em;
}


.text-input .text-input-field-padded > div.Mui-error {
  margin-bottom: 0;
}

.text-input .validateText {
  margin-left: 4em;
}