.viewCycle > .cycle-grid > div {
    margin: 1em .6em;
}

.viewCycle > .cycle-grid > div:first-child {
    margin-left: 1em;
}    

.viewCycle > .cycle-grid > div.cycle-status {
    grid-row: 2;
    grid-column: 5;
    margin: 0;
}