.user-acns .user-acn-row {
    display: flex;
    align-items: center;
}

.user-acns .user-acn-row > div:last-child {
    margin-left: auto;
}

.user-acns .user-acn-row {
    display: grid;
    grid-template-columns: 9fr 1fr;
}

.user-acns .user-acn-row .user-acn-row-data {
    display: grid;
    grid-template-columns: 1.75fr 1fr 0.75fr 0.75fr 1fr 1.75fr;
}