.file-detail-row .filename-and-buttons-row {
    display: flex;
    justify-content: space-between;
}

.file-detail-row .filename {
    padding-bottom: 0;
}

.file-detail-row .file-buttons .button {
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0.25em !important;
}

.file-detail-row .file-attributes {
    grid-template-columns: 6em 15em 2em 3em 20em auto;
}

.file-detail-row .MuiSvgIcon-colorPrimary {
    color: green !important;
}