header {
  height: var(--header-height);
}

header > .navigation-links {
  height: calc(var(--header-height) * 0.4);
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 3em;
  background-color: var(--background-color);
}

header > .navigation-links a {
  color: var(--link-color);
  text-decoration: none;
}

header > .navigation-links a label > span {
  cursor: pointer;
}

header > .navigation-links > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

header > .navigation-links .roster-provider-header > a {
  font-family: var(--font-lato-bold);
  margin-left: 1em;
  white-space: nowrap;
}

header > .navigation-links .header-right {
  justify-content: flex-end;
}

header > .navigation-links .header-right > a {
  white-space: nowrap;
  font-size: var(--font-size-nav);
}

header > .navigation-links .header-right > a,
header > .navigation-links .header-right > .notification-badge {
  padding: 0 0.5em;
  margin-left: 1.5em;
}

header > .navigation-links .header-right > a.active {
  font-family: var(--font-lato-bold);
  border-bottom: solid 3px var(--active-border-color);
}

header > .navigation-links .header-right {
  margin-right: 2em;
}

header > .logo-acting-for {
  --logo-acting-for-height: calc(var(--header-height) * 0.6);
  height: var(--logo-acting-for-height);
  display: grid;
  grid-template-columns: min-content 1fr;
  background-color: var(--acting-for-panel-background-color);
}

header > .logo-acting-for > .header-logo {
  background-color: var(--logo-background-color);
}

header > .logo-acting-for > .header-logo > a > img {
  --verical-padding: 10px;
  --horizontal-padding: 3px;
  display: block;
  padding: var(--verical-padding) var(--horizontal-padding);
  height: calc(var(--logo-acting-for-height) - calc(var(--verical-padding) * 2));
  width: auto;
}

header > .logo-acting-for > .acting-for-panel {
  display: flex;
  align-items: center;
  overflow: hidden;
}