.cycle-attestation > .cycle-attestation-summary {
    margin-bottom: 3em;
}

    .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table {
        max-height: 40vh;
        overflow: auto;
    }

        .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table > table {
            table-layout: fixed;
        }

            .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table > table th,
            .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table > table td {
                padding: 0.3em 0.3em;
            }

        .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table .cycle-attestation-summary-header {
            font-family: var(--font-lato-bold);
            font-size: var(--font-size-small);
        }

            .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table .cycle-attestation-summary-header:nth-of-type(1),
            .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table .cycle-attestation-summary-data > .cycle-attestation-summary-provider {
                width: 10em;
            }
            
            .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table .cycle-attestation-summary-header:not(:nth-of-type(1)),
            .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table .cycle-attestation-summary-data > .cycle-attestation-summary-panel-status,
            .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table .cycle-attestation-summary-data > .cycle-attestation-summary-program,
            .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table .attestation-participation-check {
                width: 6em;
            }

        .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table .cycle-attestation-summary-data {
            height: 2.5em;
        }

        .cycle-attestation > .cycle-attestation-summary > .attestation-summary-table .attestation-participation-check {
            color: var(--participation-check-color);
        }

.cycle-attestation > .cycle-attestation-confirmation {
    margin: 0 auto;
    max-width: 850px;
}

    .cycle-attestation > .cycle-attestation-confirmation > span {
        display: grid;
        grid-template-columns: 8em 1fr;
    }

    .cycle-attestation > .cycle-attestation-confirmation > .attestation-confirmation-checkbox {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1.5em;
    }

        .cycle-attestation > .cycle-attestation-confirmation > .attestation-confirmation-checkbox .attestation-confirmation-checkbox-box {
            padding-top: 0;
        }

        .cycle-attestation > .cycle-attestation-confirmation > .attestation-confirmation-checkbox .MuiFormControlLabel-label {
            font-style: italic;
        }

        .cycle-attestation > .cycle-attestation-confirmation > .attestation-confirmation-checkbox .MuiFormControlLabel-label ul {
            margin: 0;
        }

    .cycle-attestation > .cycle-attestation-confirmation > span > .attestation-confirmation-label {
        display: flex;
        align-items: center;
        font-family: var(--font-lato-bold);
        font-size: var(--font-size-small);
        margin-bottom: 1.5em;
    }

    .cycle-attestation > .cycle-attestation-confirmation > .attestation-confirmation-button {
        display: flex;
        justify-content: flex-end;
    }

        .cycle-attestation > .cycle-attestation-confirmation > .attestation-confirmation-button > .confirm-button {
            height: var(--button-height);
            margin-right: 2em;
            margin-bottom: 1em;
        }
