:root {
    --color-black: #000000;
    --color-dark-grey: #2b2e33;
    --color-medium-grey: #5b5b5b;
    --color-light-grey: #D3D3D3;
    --color-lightest-grey: #e5e5e5;
    --color-off-white: #f5f5f5;
    --color-white: #ffffff;
    --color-light-blue: #319db5;
    --color-red: #D62D20;
    --color-yellow: #FFFF00;
    --color-green: #1DA079;
    --color-opaque-black: rgba(0, 0, 0, 0.7);
    --color-translucent-black: rgba(0, 0, 0, 0.3);
    --color-disabled: rgba(0, 0, 0, 0.26);
    --background-color-disabled: rgba(0, 0, 0, 0.12);
    --darker-default-color: #26798c;
}

header {
  --background-color: var(--color-dark-grey);
  --link-color: var(--color-off-white);
  --active-border-color: var(--color-light-blue);
  --link-color-dark: var(--color-black);
  --badge-color-empty: var(--color-medium-grey);
  --badge-color: var(--color-red);
  --acting-for-panel-background-color: var(--color-lightest-grey);
  --logo-background-color: var(--color-white);
}

.user-message-panel {
  --background-color-success: var(--color-green);
  --background-color-waiting: var(--color-medium-grey);
  --background-color-error: var(--color-red);
}

.provider-view-attributes {
  --associated-product-check-color: var(--color-green);
}

.view-only {
  --panel-background-color: var(--color-lightest-grey);
}
.cycle-attestation {
    --participation-check-color: var(--color-green);
}