.unauthenticated-layout {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  min-height: 100vh;
}

.unauthenticated-layout > .registration-content {
  display: flex;
  align-items: center;
  margin: auto;
  flex: 1;
}
