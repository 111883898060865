/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

:root {
  --button-height: 2rem !important;
  --header-height: 100px;
}

body {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-base);
  background-color: var(--color-off-white);
  color: var(--color-dark-grey);
  font-family: var(--font-lato-regular);
}

body > svg {
  position: absolute;
}

a {
  color: var(--color-light-blue);
  text-decoration: underline; /* or should our default be 'none'? */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-lato-bold);
}

#addButton {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  min-width: 6.5em !important;
  height: var(--button-height);
}

.button {
  margin: 1.5em .75em !important;
  height: var(--button-height);
  min-width: max-content !important;
  white-space: nowrap;
}

#returnButton {
    margin: 1em;
    height: var(--button-height);
}

.MuiButton-root {
  text-transform: none !important;
}

/* TODO: deprecated, replace with .flexRow(?)... then remove */
.flex {
  display: flex;
  justify-content: flex-start;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* TODO: remove, unused? */
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexFill {
  flex: 1;
}

.view-only {
  background-color: var(--panel-background-color);
  word-break: break-word;
}

.view-only .checkbox-input .MuiFormControlLabel-label.Mui-disabled {
  color: inherit;
}

.name {
  font-weight: bold;
  padding-bottom: 2em;
}

.MuiTabs-fixed > .MuiTabs-flexContainer > button {
  min-width: 10em;
  max-width: 20em;
}

.MuiTabs-fixed > .MuiTabs-flexContainer > .MuiTab-textColorPrimary.Mui-selected {
    color: var(--color-white);
    background: var(--color-light-blue);
    font-weight: bold;
}

.MuiTabs-fixed > .MuiTabs-flexContainer > .MuiTab-textColorPrimary {
    color: var(--color-opaque-black);
    background: var(--color-white);
    border-right: solid var(--color-lightest-grey) 1px;
    border-bottom: solid var(--color-lightest-grey) 1px;
    border-top-left-radius: .5em;
    border-top-right-radius: .5em;
}

.MuiTabs-fixed > .MuiTabs-flexContainer > .MuiTab-textColorPrimary.Mui-disabled {
    color: var(--color-translucent-black);
    background-color: var(--color-light-grey);
    border-top-left-radius: .5em;
    border-top-right-radius: .5em;
}

.MuiTabs-fixed > .MuiTabs-indicator {
    transition: none;
    height: 0px;
}

.inactive-result {
    font-style: italic;
}

button:disabled {
  background-color: var(--background-color-disabled) !important;
}

.colHeader {
    font-size: var(--font-size-small);
    font-family: var(--font-lato-bold);
    min-height: 1em;
}

.row {
    border-bottom: 1px solid var(--color-medium-grey) !important;
    display: block !important;
}

.paging {
    margin: 0 auto;
    width: fit-content;
}

.link {
    text-decoration: underline !important;
    color: var(--darker-default-color) !important;
}

.green-button:not(.Mui-disabled), .green-button:not(.Mui-disabled):hover {
    background-color: var(--color-green);
}


.red-button:not(.Mui-disabled), .red-button:not(.Mui-disabled):hover {
    background-color: var(--color-red);
}

.validateText {
    color: var(--color-red);
}

.truncate div, .truncate span, .truncate td, .truncate tr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.trunc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
}

.import-result-list {
    margin-top: 2.5em;
}

    .import-result-list .import-result-row .import-result-row-data {
        display: grid;
        grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
    }

        .import-result-list .import-result-row .import-result-row-data .import-incomplete {
            font-family: var(--font-lato-bold);
        }

        .import-result-list .import-result-row .import-result-row-data .center,
        .import-result-list .import-result-row .import-result-row-data .import-incomplete {
            display: flex;
            justify-content: center;
        }

        .import-result-list .import-result-row .import-result-row-data .import-incomplete {
            grid-column: span 4;
        }

.import-result-heading,
.import-result-heading > h2 {
    display: flex;
    align-items: center;
}

.import-result-output {
    white-space: nowrap;
    overflow-x: auto;
}

tr.disabled td,
tr.disabled th,
tr th.disabled,
tr td.disabled {
    background-color: var(--color-lightest-grey);
}

.environment-indicator {
    height: 0.25em;
    position:absolute;
    width: 100vw;
}

.search-filter-fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.search-filter-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.MuiTooltip-tooltip {
    font-size: var(--font-size-base) !important;
    min-width: fit-content;
}