.accountable-care-networks .acn-list-buttons {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
}

    .accountable-care-networks .acn-list-buttons > button {
        height: var(--button-height);
        margin-top: unset !important;
        margin-bottom: unset !important;
    }

        .accountable-care-networks .acn-list-buttons > button:not(:last-child) {
            margin-right: 1.5em;
        }
