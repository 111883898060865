.edit-info-view-info .info-image-small {
    width: 175px;
    height: auto;
}

.edit-info-view-info .info-image-large {
    width: 350px;
    height: auto;
}

.edit-info-view-info details:not([open]) {
    padding-bottom: 1em;
}

.edit-info-view-info details summary {
    cursor: pointer;
}