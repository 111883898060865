.provider-view-attributes {
  margin: 0.5em 0;
}

.provider-view-attributes > div {
  display: flex;
  flex-direction: column;
  margin: 1em 2em;
}

.provider-view-attributes > div > div:not(:last-child) {
  margin-bottom: 1em;
}

.provider-view-attributes > div > div {
    min-height: 3.35em;
}
