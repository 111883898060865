.cycle-snapshot-label {
    padding-top: 1em;
}

.cycle-snapshot {
    padding: 1em;
}

.cycle-snapshot .snapshot-dropdown {
    align-self: center;
}

.cycle-snapshot .snapshot-radio {
    align-self: center;
    justify-self: end;
}

.cycle-snapshot .snapshot-checks {
    align-self: center;
    justify-self: end;
}

.cycle-snapshot .button {
    max-width: 12em;
    align-self: center;
    justify-self: end;
}
